<template>
  <b-card class="logisticsorder-edit-wrapper">
    <!-- form -->
    <b-form id="logisticsorderForm" class="edit-form mt-2">
      <b-col md="12">
        <div class="inner-card-title">基本信息</div>
      </b-col>

      <!--订单信息-->
      <b-col md="12">
        <b-card header="订单信息">
          <b-row>
            <!--客户名称-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="客户名称"
                  label-for="customer_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="customer_name"
                    taggable
                    push-tags
                    v-model="logisticsorder.customer_name"
                    :options=customer_name_options
                    class="select-size-sm"
                    placeholder="请选择客户名称"
                />
              </b-form-group>
            </b-col>
            <!--货物名称-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="货物名称"
                  label-for="product_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="product_name"
                    size="sm"
                    v-model="logisticsorder.product_name"
                />
              </b-form-group>
            </b-col>
            <!--件数-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="件数"
                  label-for="packages"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="packages"
                    size="sm"
                    type="number"
                    v-model="logisticsorder.packages"
                />
              </b-form-group>
            </b-col>
            <!--包装形式-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="包装形式"
                  label-for="packaging_type"
                  label-size="sm"
                  class="mb-1"
              >
                <v-select
                    id="packaging_type"
                    taggable
                    push-tags
                    v-model="logisticsorder.packaging_type"
                    :options=packaging_type_options
                    class="select-size-sm"
                    placeholder="请选择包装形式"
                />
              </b-form-group>
            </b-col>
            <!--重量(T)-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="重量(T)"
                  label-for="weight"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="weight"
                    size="sm"
                    type="number"
                    v-model="logisticsorder.weight"
                />
              </b-form-group>
            </b-col>
            <!--体积-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="体积(M³)"
                  label-for="bulk"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="bulk"
                    size="sm"
                    type="number"
                    v-model="logisticsorder.bulk"
                />
              </b-form-group>
            </b-col>
            <!--装车地址-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="装车地址"
                  label-for="address_fix"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="address_fix"
                    size="sm"
                    v-model="logisticsorder.address_fix"
                />
              </b-form-group>
            </b-col>
            <!--卸货地址-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="卸货地址"
                  label-for="address_unload"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="address_unload"
                    size="sm"
                    v-model="logisticsorder.address_unload"
                />
              </b-form-group>
            </b-col>
            <!--订单日期-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="订单日期"
                  label-for="order_date"
                  label-size="sm"
                  class="mb-1 required"
              >
                <flat-pickr
                    id="order_date"
                    v-model="logisticsorder.order_date"
                    class="form-control"
                />
              </b-form-group>
            </b-col>
            <!--备注-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="备注"
                  label-for="remark"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="remark"
                    size="sm"
                    v-model="logisticsorder.remark"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--运费信息-->
      <b-col md="12">
        <b-card header="运费信息">
          <b-row>
            <!--总费用-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="总费用"
                  label-for="total_amount"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="total_amount"
                    size="sm"
                    readonly
                    :value="addNumber(this.logisticsorder.transport_fee,logisticsorder.tax_fee)"
                />
              </b-form-group>
            </b-col>
            <!--运费-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="运费"
                  label-for="transport_fee"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="transport_fee"
                    size="sm"
                    type="number"
                    @blur="fixNumber('transport_fee')"
                    v-model="logisticsorder.transport_fee"
                />
              </b-form-group>
            </b-col>
            <!--税金-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="税金"
                  label-for="tax_fee"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="tax_fee"
                    size="sm"
                    type="number"
                    @blur="fixNumber('tax_fee')"
                    v-model="logisticsorder.tax_fee"
                />
              </b-form-group>
            </b-col>
            <!--其他费用-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="其他费用"
                  label-for="other_fee"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="other_fee"
                    size="sm"
                    type="number"
                    @blur="fixNumber('other_fee')"
                    v-model="logisticsorder.other_fee"
                />
              </b-form-group>
            </b-col>
            <!--应收费用-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="应收费用"
                  label-for="receivable_fee"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="receivable_fee"
                    size="sm"
                    type="number"
                    @blur="fixNumber('receivable_fee')"
                    v-model="logisticsorder.receivable_fee"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--承运单位信息-->
      <b-col md="12">
        <b-card header="承运单位信息">
          <b-row>
            <!--承运人-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="承运人"
                  label-for="carriager"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="carriager"
                    taggable
                    push-tags
                    v-model="logisticsorder.carriager"
                    :options=carriager_options
                    class="select-size-sm"
                    placeholder="请选择承运人"
                    @input="getCarNo"
                />
              </b-form-group>
            </b-col>
            <!--车辆类型-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车辆类型"
                  label-for="car_type"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="car_type"
                    :options="getCodeOptions('warehouse_feed_cartype')"
                    v-model="logisticsorder.car_type"
                    class="select-size-sm"
                    placeholder="请选择车辆类型"
                    @input="getCarNo"
                />
              </b-form-group>
            </b-col>
            <!--车牌号-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车牌号"
                  label-for="car_no"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="car_no"
                    :options="car_no_options"
                    v-model="car_no"
                    class="select-size-sm"
                    placeholder="请选择车牌号"
                    @input="getCarInfo($event)"
                    :disabled="car_no_disable"
                />
              </b-form-group>
            </b-col>
            <!--车型-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="车型"
                  label-for="car_cycle"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="car_cycle"
                    size="sm"
                    readonly
                    v-model="logisticsorder.car_cycle"
                />
              </b-form-group>
            </b-col>
            <!--司机姓名-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="司机姓名"
                  label-for="car_driver"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="car_driver"
                    size="sm"
                    readonly
                    v-model="logisticsorder.car_driver"
                />
              </b-form-group>
            </b-col>
            <!--司机电话-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="司机电话"
                  label-for="car_tel"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="car_tel"
                    size="sm"
                    readonly
                    v-model="logisticsorder.car_tel"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--支付信息-->
      <b-col md="12">
        <b-card header="支付信息">
          <b-row>
            <!--付款方式-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="付款方式"
                  label-for="pay_method"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="pay_method"
                    v-model="logisticsorder.pay_method"
                    :options="getCodeOptions('warehouse_feed_pay_method')"
                    class="select-size-sm"
                    placeholder="请选择付款方式"
                />
              </b-form-group>
            </b-col>
            <!--预付金额-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="预付金额"
                  label-for="pre_pay"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="pre_pay"
                    size="sm"
                    type="number"
                    @blur="fixNumber('pre_pay')"
                    v-model="logisticsorder.pre_pay"
                />
              </b-form-group>
            </b-col>
            <!--回单支付金额-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="回单支付金额"
                  label-for="receipt_pay"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="receipt_pay"
                    size="sm"
                    type="number"
                    @blur="fixNumber('receipt_pay')"
                    v-model="logisticsorder.receipt_pay"
                />
              </b-form-group>
            </b-col>
            <!--一次性支付金额-->
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="一次性支付金额"
                  label-for="one_pay"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="one_pay"
                    size="sm"
                    type="number"
                    @blur="fixNumber('one_pay')"
                    v-model="logisticsorder.one_pay"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--保存按钮-->
      <b-col
          cols="12"
          class="mt-50"
      >
        <b-button
            variant="primary"
            class="mr-1"
            @click="save"
        >
          保存
        </b-button>
        <b-button
            variant="outline-secondary"
            @click="cancel"
        >
          取消
        </b-button>
      </b-col>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import logisticsorderStore from './logisticsorderStore'
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isEmpty, second
} from '@core/utils/filter'
import axios from '@axios'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    addNumber,
    isEmpty,
    Number,
    BFormInvalidFeedback,
  },
  data() {
    return {
      id: ref(0),
      logisticsorder: ref({}),
      customer_name_options: [],
      carriager_options: [],
      car_no_options: [],
      packaging_type_options: [],
      car_no: {},
      car_no_disable: true
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('logisticsorder')) store.registerModule('logisticsorder', logisticsorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('logisticsorder')) store.unregisterModule('logisticsorder')
    })

    const edit = function () {
      store.dispatch('logisticsorder/edit', {id: this.id}).then(res => {
        this.logisticsorder = res.data.data
        console.log(this.logisticsorder)
        if (!this.logisticsorder.new) {
          this.logisticsorder.customer_name = {label: this.logisticsorder.customer_name}
          this.logisticsorder.order_date = toDate(this.logisticsorder.order_date)
          this.logisticsorder.carriager = {label: this.logisticsorder.carriager}
          if(!isEmpty(this.logisticsorder.packaging_type)){
            this.logisticsorder.packaging_type = {label: this.logisticsorder.packaging_type}
          }
          this.logisticsorder.car_type = getCode('warehouse_feed_cartype', this.logisticsorder.car_type)
          this.logisticsorder.pay_method = getCode('warehouse_feed_pay_method', this.logisticsorder.pay_method)
          this.car_no = {label: this.logisticsorder.car_no}
          this.car_no_disable = false
          this.initCarNo(this.logisticsorder.carriager.label,this.logisticsorder.car_type.value)
        }
      })
    }

    const view = function () {
      store.dispatch('logisticsorder/view', {id: this.id}).then(res => {
        this.logisticsorder = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      //校验
      let flag = true
      if (isEmpty(this.logisticsorder.customer_name)) {
        toast.error('请选择 客户名称')
        flag = false
      }
      if (isEmpty(this.logisticsorder.product_name)) {
        toast.error('请填写 货物名称')
        flag = false
      }
      if (isEmpty(this.logisticsorder.address_fix)) {
        toast.error('请填写 装车地址')
        flag = false
      }
      if (isEmpty(this.logisticsorder.address_unload)) {
        toast.error('请填写 卸货地址')
        flag = false
      }
      if (isEmpty(this.logisticsorder.order_date)) {
        toast.error('请填写 订单日期')
        flag = false
      }
      if (isEmpty(this.logisticsorder.transport_fee)) {
        toast.error('请填写 运费')
        flag = false
      }
      if (isEmpty(this.logisticsorder.tax_fee)) {
        toast.error('请填写 税金')
        flag = false
      }
      if (isEmpty(this.logisticsorder.receivable_fee)) {
        toast.error('请填写 应收费用')
        flag = false
      }
      if (isEmpty(this.logisticsorder.carriager)) {
        toast.error('请选择 承运人')
        flag = false
      }
      if (isEmpty(this.logisticsorder.car_type)) {
        toast.error('请选择 车辆类型')
        flag = false
      }
      if (isEmpty(this.car_no)) {
        toast.error('请填写 车牌号')
        flag = false
      }
      if (isEmpty(this.logisticsorder.car_cycle)) {
        toast.error('车型 不能为空')
        flag = false
      }
      if (isEmpty(this.logisticsorder.car_driver)) {
        toast.error('司机姓名 不能为空')
        flag = false
      }
      if (isEmpty(this.logisticsorder.car_tel)) {
        toast.error('司机电话 不能为空')
        flag = false
      }
      if (isEmpty(this.logisticsorder.pay_method)) {
        toast.error('请选择 付款方式')
        flag = false
      }
      if (!/^[0-9]\d*$/.test(this.logisticsorder.packages) && !isEmpty(this.logisticsorder.packages)) {
        toast.error('件数 必须是非负整数')
        flag = false
      }
      if (this.logisticsorder.weight < 0) {
        toast.error('重量 不能为负')
        flag = false
      }
      if (this.logisticsorder.bulk < 0) {
        toast.error('体积 不能为负')
        flag = false
      }
      if (this.logisticsorder.transport_fee < 0) {
        toast.error('运费 不能为负')
        flag = false
      }
      if (this.logisticsorder.tax_fee < 0) {
        toast.error('税金 不能为负')
        flag = false
      }
      if (this.logisticsorder.other_fee < 0) {
        toast.error('其他费用 不能为负')
        flag = false
      }
      if (this.logisticsorder.receivable_fee < 0) {
        toast.error('应收费用 不能为负')
        flag = false
      }
      if (this.logisticsorder.pre_pay < 0) {
        toast.error('预付金额 不能为负')
        flag = false
      }
      if (this.logisticsorder.receipt_pay < 0) {
        toast.error('回单支付金额 不能为负')
        flag = false
      }
      if (this.logisticsorder.one_pay < 0) {
        toast.error('一次性支付金额 不能为负')
        flag = false
      }

      if (!flag) return

      this.$swal({
        title: '确定数据录入无误吗？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '已确认',
        cancelButtonText: '再看看',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          //转换
          if (!isEmpty(this.logisticsorder.customer_name.label)) {
            this.logisticsorder.customer_name = this.logisticsorder.customer_name.label
          }
          if (!isEmpty(this.logisticsorder.carriager.label)) {
            this.logisticsorder.carriager = this.logisticsorder.carriager.label
          }
          if (!isEmpty(this.logisticsorder.packaging_type)) {
            if(!isEmpty(this.logisticsorder.packaging_type.label)){
              this.logisticsorder.packaging_type = this.logisticsorder.packaging_type.label
            }
          }
          this.logisticsorder.order_date = second(this.logisticsorder.order_date)
          this.logisticsorder.car_type = this.logisticsorder.car_type.value
          this.logisticsorder.pay_method = this.logisticsorder.pay_method.value
          this.logisticsorder.total_amount = addNumber(this.logisticsorder.transport_fee, this.logisticsorder.tax_fee)
          this.logisticsorder.car_no = this.car_no.label

          store.dispatch('logisticsorder/save', this.logisticsorder).then(res => {
            if (res.data.code == 0) {
              toast.success('数据已保存!')
              this.$router.push({name: 'apps-logisticsorder-list'});
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const initOptions = function () {
      let that = this
      axios.post('/api/logisticsuser/list').then(function (res) {
        let list = res.data.data.list
        list.forEach(item => {
          if (item.user_type === 1) {
            that.customer_name_options.push({label: item.user_name})
          } else if (item.user_type === 2) {
            that.carriager_options.push({label: item.user_name})
          } else {
            that.packaging_type_options.push({label: item.user_name})
          }
        })
      })
    }

    const fixNumber = function (type) {
      if (!isEmpty(this.logisticsorder[type])) {
        this.logisticsorder[type] = Number(this.logisticsorder[type]).toFixed(2)
      } else {
        this.logisticsorder[type] = ''
      }
      this.$forceUpdate()
    }

    const getCarInfo = function (param) {
      if (isEmpty(param)) {
        this.logisticsorder.car_cycle = ''
        this.logisticsorder.car_driver = ''
        this.logisticsorder.car_tel = ''
      }
      let that = this
      axios.post('/api/logisticscarriager/getCarInfo', {car_no: param.label}).then(function (res) {
        if (res.data.code == 1) {
          that.logisticsorder.car_cycle = ''
          that.logisticsorder.car_driver = ''
          that.logisticsorder.car_tel = ''
        } else {
          that.logisticsorder.car_cycle = res.data.data.car_cycle
          that.logisticsorder.car_driver = res.data.data.car_driver
          that.logisticsorder.car_tel = res.data.data.car_tel
        }
        that.$forceUpdate()
      })
    }

    //获取车牌号
    const getCarNo = function () {
      //清空
      this.car_no = {}
      this.logisticsorder.car_cycle = ''
      this.logisticsorder.car_driver = ''
      this.logisticsorder.car_tel = ''
      this.car_no_options = []
      if (isEmpty(this.logisticsorder.carriager) || isEmpty(this.logisticsorder.car_type)) {
        this.car_no_disable = true
      } else {
        this.car_no_disable = false
        let that = this
        axios.post('/api/logisticscarriager/getCarNo',
            {
              carriager: this.logisticsorder.carriager.label,
              car_type: this.logisticsorder.car_type.value
            }).then(function (res) {
          if (res.data.code == 0) {
            let resList = res.data.data
            resList.forEach(item => {
              that.car_no_options.push({label: item.car_no})
              that.$forceUpdate()
            })
          }
        })
      }
    }

    //初始化车牌号
    const initCarNo = function (carriager,car_type){
      let that = this
      axios.post('/api/logisticscarriager/getCarNo',
          {
            carriager: carriager,
            car_type: car_type
          }).then(function (res) {
        if (res.data.code == 0) {
          let resList = res.data.data
          resList.forEach(item => {
            that.car_no_options.push({label: item.car_no})
            that.$forceUpdate()
          })
        }
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      initOptions,
      fixNumber,
      getCarInfo,
      getCarNo,
      initCarNo,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      addNumber,
      isEmpty,
      Number,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
    this.initOptions()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
